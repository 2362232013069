import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language);

  const handleChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <Box sx={{ minWidth: 120, marginBottom: "auto", marginTop: "auto" }}>
      <FormControl fullWidth>
        <Select
          value={language}
          onChange={handleChange}
          displayEmpty
          MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: 'rgba(193, 193, 193, 0.7)',  // Updated dropdown background
                color: 'white',  // Dropdown text color
              }
            }
          }}
          sx={{
            color: 'white',  // Makes the selected item white
            '& .MuiSelect-icon': {
              color: 'white',  // Makes the dropdown arrow white
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',  // Removes the border
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none'  // Ensures the border does not reappear on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none'  // Ensures the border does not reappear when the select is focused
            }
          }}
        >
          <MenuItem value="en" style={{ fontFamily: 'Nunito Sans', color: 'white' }}>English</MenuItem>
          <MenuItem value="it" style={{ fontFamily: 'Arial', color: 'white' }}>Italian</MenuItem>
          <MenuItem value="fr" style={{ fontFamily: 'Arial', color: 'white' }}>French</MenuItem>
          <MenuItem value="arb" style={{ fontFamily: 'Arial', color: 'white' }}>Arabic</MenuItem>
          <MenuItem value="hu" style={{ fontFamily: 'Arial', color: 'white' }}>Hungarian</MenuItem>

        </Select>
      </FormControl>
    </Box>
  );
}
